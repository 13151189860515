import React from "react"

export const TitleUnderlineRightOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-3px" }}
      width="261" height="9" viewBox="0 0 261 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M48.3437 8.33102C42.9774 7.42759 3.63221 8.25375 0.76442 7.99818C-1.58196 7.64156 2.02453 1.47805 4.17537 0.568684C4.93577 0.24773 5.82648 0.18235 7.8687 0.354714C9.49812 0.485473 20.7086 0.467639 31.528 0.402259C42.3474 0.33688 52.7757 0.259614 52.7757 0.259614C53.3189 0.455753 54.9918 -0.180211 55.2959 0.0515895C55.7739 0.324995 72.8937 0.372544 90.6218 0.372544C108.35 0.372544 126.687 0.354711 129.554 0.360654C133.052 0.366598 130.25 0.717274 134.377 0.610289C143.155 0.384432 162.23 0.883691 168.878 0.616229C174.222 0.40226 175.352 0.414147 180.436 0.681609C185.607 0.955015 213.329 0.996621 216.544 0.622174C217.326 0.515189 217.913 0.562737 217.913 0.699439C218.282 0.71727 237.401 1.11549 237.466 1.21653C237.596 1.35324 238.183 1.4305 238.748 1.34135C239.79 1.21059 260.625 1.01445 260.886 1.41862C261.69 2.57762 258.062 7.38004 255.52 8.5331C252.848 9.75154 239.617 8.12894 228.667 8.73518C168.943 8.00412 180.827 8.06356 167.335 8.50338C166.14 8.07544 161.317 9.04425 158.449 8.29536C157.276 7.98629 148.695 7.86742 146.739 8.14677C144.588 8.45584 101.593 7.30277 96.553 8.17054C88.5579 7.40976 55.9042 8.19432 48.3437 8.33102Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineRightTwo = ({ underline }) => (
  <span>
    {underline}
    <svg    style={{ bottom: "-3px" }} width="179" height="9" viewBox="0 0 179 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.1552 8.33102C29.4749 7.42759 2.49106 8.25375 0.524257 7.99818C-1.08494 7.64156 1.38847 1.47805 2.86357 0.568684C3.38507 0.24773 3.99594 0.18235 5.39654 0.354714C6.51404 0.485473 14.2025 0.467639 21.6227 0.402259C29.0429 0.33688 36.1948 0.259614 36.1948 0.259614C36.5673 0.455753 37.7147 -0.180211 37.9233 0.0515895C38.2511 0.324995 49.9922 0.372544 62.1506 0.372544C74.309 0.372544 86.8846 0.354711 88.8514 0.360654C91.2503 0.366598 89.3282 0.717274 92.1592 0.610289C98.1788 0.384432 111.261 0.883691 115.82 0.616229C119.486 0.40226 120.261 0.414147 123.747 0.681609C127.293 0.955015 146.306 0.996621 148.511 0.622174C149.047 0.515189 149.45 0.562737 149.45 0.699439C149.703 0.71727 162.815 1.11549 162.86 1.21653C162.949 1.35324 163.351 1.4305 163.739 1.34135C164.454 1.21059 178.743 1.01445 178.922 1.41862C179.473 2.57762 176.985 7.38004 175.242 8.5331C173.409 9.75154 164.335 8.12894 156.825 8.73518C115.865 8.00412 124.015 8.06356 114.763 8.50338C113.943 8.07544 110.635 9.04425 108.668 8.29536C107.864 7.98629 101.978 7.86742 100.637 8.14677C99.1622 8.45584 69.6751 7.30277 66.2183 8.17054C60.7351 7.40976 38.3404 8.19432 33.1552 8.33102Z" fill="#FFBA00" />
    </svg>    
  </span>
)