import React from "react"

export const TitleUnderlineWhatsappInteractivosOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="434" height="10" viewBox="0 0 434 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M80.5774 9.68372C71.6199 8.68671 6.00466 9.53261 1.23655 9.22483C-2.65566 8.82097 3.58892 1.97308 7.20627 0.974212C8.47424 0.615028 9.94611 0.567349 13.3174 0.744563C18.663 1.07422 87.7976 0.703568 87.7976 0.703568C88.7098 0.918378 91.4506 0.213961 91.9527 0.473606C93.5342 1.08089 205.591 0.656944 215.148 0.657653C220.942 0.658289 216.357 1.05204 223.176 0.91083C237.708 0.631574 269.377 1.06086 280.388 0.722875C289.2 0.443246 291.1 0.436746 299.557 0.717887C308.2 0.973238 354.159 0.811639 359.414 0.347526C360.7 0.219828 361.687 0.269605 361.687 0.421681C361.687 0.454741 394.082 0.681612 394.231 0.801154C394.473 0.954086 395.46 1.0303 396.373 0.927736C398.069 0.775038 432.553 0.321635 433.092 0.766548C434.783 2.04204 430.015 7.43387 426.006 8.73551C421.903 10.1294 399.404 8.46961 381.312 9.25203L297.09 9.04039L279.036 9.50556C276.97 9.04202 269.011 10.1644 264.208 9.32753C262.178 8.99637 247.834 8.89939 244.666 9.23203C241.908 9.48676 168.53 8.55966 161.001 9.45214C147.59 8.63775 93.1899 9.54315 80.5774 9.68372Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineWhatsappInteractivosTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="299" height="10" viewBox="0 0 299 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M55.5888 10C49.4071 9.07169 4.14324 10.1081 0.853199 9.82831C-1.83281 9.45317 2.45967 2.84689 4.95291 1.87407C5.82682 1.52436 6.84211 1.47351 9.16827 1.63247C12.8567 1.93131 60.5495 1.33997 60.5495 1.33997C61.1793 1.54344 63.0685 0.856744 63.4155 1.10472C64.5079 1.68332 141.811 0.894891 148.404 0.8631C152.401 0.844025 149.239 1.23824 153.943 1.07928C163.967 0.761364 185.815 1.06657 193.411 0.704142C199.49 0.405302 200.801 0.392594 206.635 0.63421C212.598 0.850392 244.304 0.538832 247.928 0.074676C248.815 -0.0524901 249.496 -0.00797814 249.496 0.138263C249.496 0.170054 271.845 0.278142 271.948 0.392591C272.115 0.538832 272.796 0.608769 273.426 0.507036C274.595 0.354437 298.384 -0.198737 298.757 0.227269C299.926 1.44806 296.649 6.64915 293.886 7.91446C291.058 9.26878 275.533 7.74915 263.054 8.56302L204.952 8.64568L192.498 9.15434C191.072 8.71561 185.584 9.82196 182.268 9.03353C180.868 8.72197 170.972 8.67746 168.787 9.00809C166.885 9.26242 116.262 8.62023 111.07 9.50403C101.816 8.76647 64.2894 9.82197 55.5888 10Z" fill="#FFBA00" />
    </svg>
  </span>
)