import React from "react"

export const TitleUnderlineTestimonyOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="163" height="10" viewBox="0 0 163 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.2314 8.72465C26.4629 7.97829 1.9643 10.1013 0.560628 9.99622C-0.926019 9.75296 0.871788 3.89821 2.15791 2.96941C2.94618 2.4055 2.89086 2.81461 9.68795 2.4055C9.68795 2.4055 19.9355 1.80842 32.5339 1.11182C32.5339 1.11182 52.7731 0.210661 80.6391 0.000575625C82.831 -0.0160101 81.0954 0.332289 83.6746 0.210661C89.1718 -0.0381242 101.134 0.431804 105.297 0.232775C108.636 0.072447 109.356 0.0613902 112.543 0.365461C114.735 0.575547 133.128 1.09523 135.168 0.780103C135.666 0.702703 136.025 0.75246 136.018 0.879617C136.164 0.890674 148.216 1.64809 148.271 1.76419C148.354 1.89687 148.721 1.9798 149.073 1.91346C149.723 1.7863 162.757 2.17883 162.93 2.56583C163.428 3.67154 161.153 8.03911 159.556 9.05083C157.903 10.0957 149.889 8.26025 142.809 8.57538C109.784 6.94445 110.04 7.35909 104.522 7.57471C103.762 7.17112 100.761 8.03911 98.9767 7.34804C98.2299 7.05502 92.8642 6.94445 91.661 7.19324C91.4052 7.24852 89.4898 7.23746 86.724 7.19876C85.341 7.18218 83.7438 7.15454 82.0358 7.13242C79.754 7.09925 62.4882 6.94998 60.3239 7.59129C54.5017 6.94998 35.77 8.33765 30.2314 8.72465Z" fill="#FFBA00" />
    </svg>
  </span>
)


export const TitleUnderlineTestimonyTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="146" height="10" viewBox="0 0 146 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.0784 8.72465C23.7029 7.97829 1.75943 10.1013 0.502158 9.99622C-0.829441 9.75296 0.780865 3.89821 1.93285 2.96941C2.63891 2.4055 2.58936 2.81461 8.67755 2.4055C8.67755 2.4055 17.8563 1.80842 29.1408 1.11182C29.1408 1.11182 47.2691 0.210661 72.2289 0.000575625C74.1922 -0.0160101 72.6376 0.332289 74.9478 0.210661C79.8716 -0.0381242 90.5864 0.431804 94.3148 0.232775C97.3063 0.072447 97.9504 0.0613902 100.806 0.365461C102.769 0.575547 119.244 1.09523 121.071 0.780103C121.517 0.702703 121.839 0.75246 121.832 0.879617C121.963 0.890674 132.758 1.64809 132.807 1.76419C132.882 1.89687 133.21 1.9798 133.526 1.91346C134.108 1.7863 145.783 2.17883 145.937 2.56583C146.383 3.67154 144.346 8.03911 142.915 9.05083C141.435 10.0957 134.257 8.26025 127.914 8.57538C98.3344 6.94445 98.5636 7.35909 93.6212 7.57471C92.9399 7.17112 90.2519 8.03911 88.654 7.34804C87.9851 7.05502 83.179 6.94445 82.1013 7.19324C81.8721 7.24852 80.1565 7.23746 77.6791 7.19876C76.4404 7.18218 75.0097 7.15454 73.48 7.13242C71.4361 7.09925 55.971 6.94998 54.0324 7.59129C48.8175 6.94998 32.0394 8.33765 27.0784 8.72465Z" fill="#FFBA00" />
    </svg>
  </span>
)
