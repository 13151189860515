import React, { useEffect, useRef } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"

const Customer = ({ data1, data2, title }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  //funcion para slider de logos clientes
  var settings = {
    arrows: true,
    autoplay: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          initialSlide: 0,
        },
      },
    ],
  }
  return (
    <section className="container-customer-logos">
      <p className="container-customer-logos-title">
       {title}
      </p>

        {/*Seccion Desktop  */}
        <section className="container-customer-logos-desktop">
          {data1.map(elemento => {
            return (
              <div key={elemento} className="container-customer-logos-desktop-client">
                <img className="container-customer-logos-desktop-image" src={elemento} alt="" />
              </div>
            )
          })}
        </section>

        {/*Seccion Mobile  */}
        <section style={{display: "flex", justifyContent: "center"}}>
        <section className="container-customer-logos-mobile" ref={fromRef}>
          <Slider ref={sliderRef} {...settings}>
            <div className="container-customer-logos-mobile-card">
              {data2.arrayOne.map(elemento => {
                return (
                  <div key={elemento} className="container-customer-logos-mobile-card-client">
                    <img className="container-customer-logos-mobile-card-image" src={elemento} alt="" />
                  </div>
                )
              })}
            </div>
            {data2.arrayTwo ? (
              <div className="container-customer-logos-mobile-card">
                {data2.arrayTwo.map(elemento => {
                  return (
                    <div key={elemento} className="container-customer-logos-mobile-card-client">
                      <img className="container-customer-logos-mobile-card-image" src={elemento} alt="" />
                    </div>
                  )
                })}
              </div>
            ) : (
              ""
            )}
          </Slider>
        </section>
        </section>

    </section>
  )
}
export default Customer
